import React from 'react';
import { SEO, Layout, Header, Footer, Conditions, BookFlightForm } from '../../components';
//
import cssModule from './index.module.scss';

export default ({ pageContext: ctx, location: { search } }) => {
  const {
    heroSection,
    conditionSection,
    aircrafts,
    translationPath,
    metadata,
    i18n_lang: lang,
  } = ctx;
  //
  const params = new URLSearchParams(search);
  const selectedAircraft = params.get('slug') || null;

  return (
    <Layout>
      <SEO {...metadata.metadata} />
      <Header
        {...heroSection}
        lang={lang}
        translationPath={translationPath}
        slug={selectedAircraft}
      />
      <section className={cssModule.bookFlight}>
        <Conditions {...conditionSection} />
        <BookFlightForm lang={lang} aircrafts={aircrafts} selectedAircraft={selectedAircraft} />
      </section>
      <Footer lang={lang} />
    </Layout>
  );
};
